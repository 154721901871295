import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import Tracking from "../assets/Digital-Logbook/icon-tracking.svg";
import Provenance from "../assets/Digital-Logbook/icon-providence.svg";
import ProtectedValue from "../assets/Digital-Logbook/icon-value.svg";
import Immutable from "../assets/Digital-Logbook/icon-immutable.svg";
import Compliant from "../assets/Digital-Logbook/icon-compliant.svg";
import Trusted from "../assets/Digital-Logbook/icon-trusted.svg";
import LeaseReturns from "../assets/Digital-Logbook/automated-lease-returns.svg";
import CapturingData from "../assets/Digital-Logbook/capturing-data.svg";
import TechLog from "../assets/product-tech-log.svg";
import TaskCard from "../assets/product-task-cards.svg";
import Registry from "../assets/product-aircraft-registry.svg";
import Started from "../components/Layout/started";
import AlsoInterestedIn from "../components/Layout/alsoInterestedIn";
import Layout from "../components/layout";
import PageHeader from "../components/pageHeader";
import Seamless from "../components/Layout/seamlessIntegration";
import { graphql } from "gatsby";
import pageStyles from "./page.module.less";
import styles from "./logbook.module.less";
import SEO from "../components/SEO";
import ScrollAnimation from "react-animate-on-scroll";

const TitleSpan = styled.h3`
  display: flex;
  justify-content: center;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: center;
  color: #242d41;
  margin-bottom: 30px;
  @media screen and (max-width: 1200px) {
    font-size: 26px;
  }
  @media screen and (max-width: 991px) {
    font-size: 24px;
  }
  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
  @media screen and (max-width: 576px) {
    font-size: 20px;
  }
`;

const MediumTitleSpan = styled.h4`
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.04px;
  text-align: left;
  color: #242d41;
  margin-bottom: 30px;
  display: inline-block;
  @media screen and (max-width: 1200px) {
    font-size: 20px;
  }
  @media screen and (max-width: 991px) {
    font-size: 18px;
  }
  @media screen and (max-width: 768px) {
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    text-align: center;
    width: 100%;
  }
`;

const SmallerText = styled.p`
  font-size: 16px;
  line-height: 1.76;
  letter-spacing: 0.03px;
  ${(props) => (props.textLeft ? "text-align: left" : "text-align: center")};
  color: rgba(36, 45, 65, 0.7);
  width: 100%;
  ${(props) => props.marginBottom && "margin-bottom: 70px"};
  display: inline-block;
  font-weight: 300;
  @media screen and (max-width: 576px) {
    font-size: 14px;
    text-align: center;
  }
`;

const StyledImage = styled.img`
  height: 25px;
  width: auto;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  text-align: left;
  width: 100%;
  margin-bottom: 15px;
  span {
    display: inline-block;
    margin-left: 8px;
    width: 100%;
    font-weight: 600;
    color: #242d41;
    font-size: 16px;
  }
  @media screen and (max-width: 576px) {
    justify-content: center;
    span {
      text-align: center;
      width: auto;
    }
  }
`;

const TinyText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: rgba(36, 45, 65, 0.7);
`;

const TopCardWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 60px;
  padding-right: 40px;
  @media screen and (max-width: 576px) {
    margin-bottom: 30px;
    text-align: center;
    padding-right: 0;
  }
`;

const GradientSection = styled.div`
  background-image: linear-gradient(to top, #f0f5f9, #ffffff);
  margin-bottom: 45px;
`;

export default (props) => {
  const { mainImage } = props.data;

  const seamlessCards = [
    {
      img: TechLog,
      title: "E-Tech Log",
    },
    {
      title: "And more",
    },
    {
      img: TaskCard,
      title: "Task Cards",
    },
    {
      img: Registry,
      title: "Aircraft Registry",
    },
  ];

  return (
    <Layout>
      <SEO
        title="Digital Engine Log"
        description="The single-source of your aircraft and component history. Validated, Immutable and Secure."
      />
      <PageHeader
        header="Digital Engine Log."
        subHeader="The single-source of your aircraft and component history. <br /><br /><span>Validated, Immutable</span> and <span>Secure.</span>"
        linkText="Schedule a demo"
        bigHeader
        bigImage={mainImage.childImageSharp.fluid}
        link="/contact?demo&logbook"
      />
      <GradientSection>
        <Container>
          <Row
            className={`justify-content-md-center ${pageStyles.pageParentRow}`}
          >
            <Col xl={10} lg={11} md={12}>
              <Row>
                <Col>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInDown"
                    offset={0}
                  >
                    <TitleSpan>How does it work?</TitleSpan>
                  </ScrollAnimation>
                </Col>
              </Row>
              <Row className="justify-content-md-center">
                <Col xl={8} lg={10}>
                  <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
                    <SmallerText marginBottom>
                      TrustFlight's Digital Engine Log automatically maintains a
                      complete history of every serialised part on your
                      airframe. Each flight it travels on the aircraft and every
                      event that impacts it is recorded securely in our
                      immutable data store, so you can be confident that value
                      is maximised through fully verified records.
                    </SmallerText>
                  </ScrollAnimation>
                </Col>
              </Row>
              <Row
                className={`justify-content-md-center ${styles.logbookCardsWrapper}`}
              >
                <Col xl={10} lg={11} md={12}>
                  <Row>
                    <Col md={4} sm={6}>
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInLeft"
                        offset={0}
                      >
                        <TopCardWrapper>
                          <CardHeader>
                            <StyledImage src={Tracking} />
                            <span>Full Part Tracking</span>
                          </CardHeader>
                          <TinyText>
                            Each serialised part is treated independently and
                            every event recorded, providing a full history of
                            every single component from its inception through to
                            end of life.
                          </TinyText>
                        </TopCardWrapper>
                      </ScrollAnimation>
                    </Col>
                    <Col md={4} sm={6}>
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        offset={0}
                      >
                        <TopCardWrapper>
                          <CardHeader>
                            <StyledImage src={Provenance} />
                            <span>Assured Provenance</span>
                          </CardHeader>
                          <TinyText>
                            Purchasing a part with a TrustFlight Logbook means
                            that you know everything about it from the airframes
                            it was fitted to, right down to the landings it
                            experienced.
                          </TinyText>
                        </TopCardWrapper>
                      </ScrollAnimation>
                    </Col>
                    <Col md={4} sm={6}>
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInRight"
                        offset={0}
                      >
                        <TopCardWrapper>
                          <CardHeader>
                            <StyledImage src={ProtectedValue} />
                            <span>Protected Value</span>
                          </CardHeader>
                          <TinyText>
                            The value of a part depends on its history. With the
                            Digital Engine Log, this is immutable, allowing a
                            buyer to be confident in the quality and
                            authenticity of the part being offered.
                          </TinyText>
                        </TopCardWrapper>
                      </ScrollAnimation>
                    </Col>
                    <Col md={4} sm={6}>
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInLeft"
                        offset={0}
                      >
                        <TopCardWrapper>
                          <CardHeader>
                            <StyledImage src={Immutable} />
                            <span>Immutable</span>
                          </CardHeader>
                          <TinyText>
                            All data around parts and aircraft is immutable,
                            ensuring that the data cannot be doctored and
                            thereby giving you confidence in the parts you own.
                          </TinyText>
                        </TopCardWrapper>
                      </ScrollAnimation>
                    </Col>
                    <Col md={4} sm={6}>
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInUp"
                        offset={0}
                      >
                        <TopCardWrapper>
                          <CardHeader>
                            <StyledImage src={Compliant} />
                            <span>Fully Compliant</span>
                          </CardHeader>
                          <TinyText>
                            All data within our platform adheres to industry
                            standards such as ATA Spec2000, in addition to being
                            fully GDPR, EASA and FAA compliant. This ensures
                            that your data can be transferred between compliant
                            systems.
                          </TinyText>
                        </TopCardWrapper>
                      </ScrollAnimation>
                    </Col>
                    <Col md={4} sm={6}>
                      <ScrollAnimation
                        animateOnce
                        animateIn="fadeInRight"
                        offset={0}
                      >
                        <TopCardWrapper>
                          <CardHeader>
                            <StyledImage src={Trusted} />
                            <span>Trusted</span>
                          </CardHeader>
                          <TinyText>
                            Data added to the platform goes through a
                            multi-stage, event-based validation process to
                            ensure its accuracy.
                          </TinyText>
                        </TopCardWrapper>
                      </ScrollAnimation>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row
                className={`${styles.oppositeRow} ${pageStyles.pageParentRow}`}
              >
                <Col md={7} sm={6}>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    offset={0}
                  >
                    <MediumTitleSpan>Automated Lease Returns</MediumTitleSpan>
                    <SmallerText textLeft>
                      With our Digital Engine Log, we can provide a real-time
                      overview of airframe compliance against a number of
                      requirements including lease return restrictions. This
                      greatly reduces most of the work at the end of the lease
                      and lowers the risk of any airframe non-compliances or
                      unforeseen lease extensions.
                    </SmallerText>
                  </ScrollAnimation>
                </Col>
                <Col md={5} sm={6} className={styles.colImage}>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInRight"
                    offset={0}
                  >
                    <img src={LeaseReturns} alt="lease returns" />
                  </ScrollAnimation>
                </Col>
              </Row>
              <Row className={styles.oppositeRow}>
                <Col md={6} sm={7} className={styles.colImage}>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInLeft"
                    offset={0}
                  >
                    <img src={CapturingData} alt="capture data" />
                  </ScrollAnimation>
                </Col>
                <Col md={6} sm={5}>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInRight"
                    offset={0}
                  >
                    <MediumTitleSpan>
                      Capturing data across the complete aircraft&nbsp;lifecycle
                    </MediumTitleSpan>
                    <SmallerText textLeft>
                      By covering all Aircraft related events from flights
                      through to maintenance events, you can be sure that the
                      value of every serialised part is maintained.
                    </SmallerText>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </GradientSection>
      <Container>
        <Row
          className={`justify-content-md-center ${styles.logbookCardsWrapper}`}
        >
          <Col lg={10} md={12}>
            <Seamless
              title="Seamless Integrations"
              text="Interoperability ensures quick and effective data sharing between trusted stakeholders and common aviation software applications."
              cards={seamlessCards}
            />
          </Col>
        </Row>
        <Started />
      </Container>
      <AlsoInterestedIn page="logbook" />
    </Layout>
  );
};

export const query = graphql`
  query LogbookQuery {
    mainImage: file(relativePath: { eq: "Digital-Logbook/logbook@1x.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
